<template>
  <v-container :style="containerHeight">
    <backArrow v-if="detailOnly" :action="() => { clearExam(); close(); }" />
    <v-row v-if="!detailOnly">
      <v-col class="pb-0 py-5" cols="10">
        <p v-if="!realizeType" class="main-strong-text mb-0">0{{ step }} de 04</p>
      </v-col>
      <v-col class="pb-0" cols="2">
        <img @click="() => { clearExam(); close(); }" src="/img/icons/common/close-icon.svg" style="height: 35px;" />
      </v-col>
    </v-row>
    <v-row v-if="step === steps.exam && !exam">
      <v-col class="pt-0" cols="12">
        <p class="main-title" style="font-size: 32px">
          Informe qual o exame e o tipo
        </p>
      </v-col>
      <v-col cols="12">
        <v-text-field
          :value="specialityName"
          readonly
          placeholder="Exame"
          @click="() => {form.comment = null; examSpeciality = true}"
        >
          <img
            slot="prepend"
            src="/img/icons/common/search-gray.svg"
            style="width: 20px; height: 20px"
          />
        </v-text-field>
      </v-col>
      <v-col cols="12">
        <p class="main-strong-text" style="font-size: 16px">Tipo</p>
        <v-chip
          :outlined="form.examType.code !== examTypes.self"
          color="primary"
          @click="() => getExamTypes(examTypes.self)"
        >
          Iniciativa própria
        </v-chip>
        <v-chip
          class="ml-2"
          :outlined="form.examType.code !== examTypes.refer"
          color="primary"
          @click="() => getExamTypes(examTypes.refer)"
        >
          Indicação médica
        </v-chip>
        <v-checkbox
          class="mt-16"
          v-model="form.fullfilled"
          label="Esse exame já foi realizado"
        />
      </v-col>
    </v-row>
    <v-row v-if="step === steps.exam && exam">
      <v-col class="pt-0" cols="12">
        <p class="main-title" style="font-size: 32px">
          Registrar exame
        </p>
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="form.examSpeciality.description"
          readonly
          placeholder="Exame"
          @click="() => examSpeciality = true"
        >
          <img
            slot="prepend"
            src="/img/icons/common/search-gray.svg"
            style="width: 20px; height: 20px"
          />
        </v-text-field>
      </v-col>
      <v-col cols="12">
        <p class="main-strong-text" style="font-size: 16px">Tipo</p>
        <v-chip
          color="primary"
        >
          {{ getExamTypeText() }}
        </v-chip>
      </v-col>
      <v-col cols="12">
        <v-checkbox
          class="mt-16"
          v-model="form.fullfilled"
          label="Esse exame já foi realizado"
        />
      </v-col>
    </v-row>
    <v-row v-if="step === steps.laboratory && !form.laboratory.laboratoryName">
      <v-col class="pt-0" cols="12">
        <p class="main-title" style="font-size: 32px">
          Qual laboratório?
        </p>
      </v-col>
      <v-col cols="12">
        <v-text-field
          placeholder="Buscar"
          v-model="searchLaboratory"
          @change="() => {filterLaboratoriesHistory(); addLaboratory.laboratoryName = searchLaboratory}"
        >
          <img
            slot="prepend"
            src="/img/icons/common/search-gray.svg"
            style="width: 20px; height: 20px"
            @click="() => filterLaboratoriesHistory()"
          />
          <img
            v-if="searchLaboratory"
            slot="append"
            src="/img/icons/common/close-icon.svg"
            style="width: 20px; height: 20px"
            @click="() => searchLaboratory = ''"
          />
        </v-text-field>
        <p v-if="allLaboratories.length > 0 && searchLaboratory" class="main-strong-text mt-2" style="font-size: 16px">
          Recentes
        </p>

        <v-row
          v-for="(laboratory,index) in laboratoryHistory"
          :key="index"
          class="my-2 py-0"
          @click="() => form.laboratory = laboratory"
        >
          <v-col
            v-if="laboratory.laboratoryId && /^[a-zA-Z()]+$/.test(laboratory.laboratoryId.replace(/[0-9]/g, ''))"
             class="pr-0 my-0 py-0" cols="1"
          >
            <img
              src="/img/icons/exame/building-icon.svg"
              style="width: 20px; height: 20px; margin-top: 2px"
            />
          </v-col>
          <v-col v-else class="pr-0 my-0 py-0" cols="1">
            <img
              src="/img/icons/common/clock-icon.svg"
              style="width: 20px; height: 20px; margin-top: 2px"
            />
          </v-col>
          <v-col class="my-0 py-0" cols="11">
            <p
              class="main-text"
              style="font-size: 16px"
            >
              {{ laboratory.laboratoryName }}
            </p>
          </v-col>
        </v-row>

        <p v-if="allLaboratories.length > 0" class="main-strong-text" style="font-size: 16px">
          Lista de laboratórios
        </p>

        <div
          v-if="searchLaboratory"
        >
          <v-row
            v-for="laboratory in allLaboratories"
            :key="laboratory.id"
            class="mt-4 mb-2"
            @click="() => form.laboratory = {
            laboratoryName: laboratory.name,
            laboratoryId: laboratory.id,
            laboratoryPhone: laboratory.phone
            }"
          >
            <v-col class="pr-0" cols="1">
              <img
                src="/img/icons/exame/building-icon.svg"
                style="width: 20px; height: 20px; margin-top: 2px"
              />
            </v-col>
            <v-col cols="11">
              <p
                class="main-text"
                style="font-size: 16px"
              >
                {{ laboratory.name }}
              </p>
            </v-col>
          </v-row>
        </div>

        <v-row v-if="laboratoryNotFound()">
          <v-col cols="12">
            <p
              class="main-text"
              style="font-size: 16px"
            >
              Laboratório não encontrado
            </p>
          </v-col>
          <v-col cols="12">
            <v-chip
              color="primary"
              @click="() => newLaboratory = true"
            >
              adicionar laboratório
            </v-chip>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-if="step === steps.laboratory && form.laboratory.laboratoryName">
      <v-col class="pt-0" cols="12">
        <p class="main-title" style="font-size: 32px">
          Qual laboratório?
        </p>
      </v-col>
      <v-col cols="12">
        <v-text-field
          readonly
          class="mb-2"
          label="Nome do laboratório"
          v-model="form.laboratory.laboratoryName"
        />
        <v-text-field
          readonly
          class="mb-2"
          v-mask="['##.###.###/####-##']"
          label="CNPJ"
          v-model="form.laboratory.laboratoryCnpj"
        />
        <v-text-field
          readonly
          class="mb-2"
          v-mask="['(##) ####-####', '(##) #####-####']"
          label="Telefone"
          v-model="form.laboratory.laboratoryPhone"
        />
      </v-col>
    </v-row>
    <v-row v-if="step === steps.date">
      <v-col class="pt-0" cols="12">
        <p class="main-title" style="font-size: 32px">
          Informe a data, hora e o local do agendamento
        </p>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col cols="6">
            <v-text-field
              @click="() => addDate = true"
              placeholder="Data do exame"
              readonly
              :value="formatDateInput"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              @click="() => addHour = true"
              placeholder="Horário"
              readonly
              v-model="form.hour"
            />
          </v-col>
          <v-col v-if="(!mapsCep && !form.place) || editAddress" cols="12">
            <google-map-autocomplete @input="upateAddress" placeholder="Digite um local"/>
          </v-col>
          <v-col v-if="!mapsCep && form.place &&  form.place.length > 0 && !editAddress" cols="12">
            <p style="font-size: 20px; color: #3F3F3F" class="main-strong-text">
              Local
            </p>
            <v-row class="mb-2">
              <v-col cols="10">
                <p
                  class="generic-text"
                  style="font-size: 16px"
                >
                  {{ form.place }}
                </p>
              </v-col>
              <v-col v-if="detailOnly && !fullfilledStatus" @click="() => editAddress = true" cols="2">
                <img
                  src="/img/icons/common/edit-pencil-pink.svg"
                  style="width: 25px; height: 25px;"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col class="pl-0 pr-0" cols="12" v-if="mapsCep">
            <p style="font-size: 20px; color: #3F3F3F" class="main-strong-text">
              Local
            </p>
            <googleMap
              :address="form.place"
              :zip-code="mapsCep"
              :get-city="getCity"
              :get-state="getState"
            />
            <p v-if="!exam" style="font-size: 14px; color: #3F3F3F" class="main-text mb-1 mt-2">
              {{ form.place }}{{ local.city.long_name }} - {{ local.state.short_name }}
            </p>
            <p v-if="!exam" style="font-size: 14px; color: #3F3F3F" class=" mb-0 main-text">
              {{ local.cep }}
            </p>
            <p v-if="exam" style="font-size: 14px; color: #3F3F3F" class="main-text mb-1 mt-2">
              {{ form.place }}
            </p>
          </v-col>
          <v-col cols="12" class="mt-6">
            <v-row class="mt-2 mb-2">
              <v-col cols="1">
                <img
                  src="/img/icons/common/bell-icon.svg"
                  style="width: 25px; height: 25px;"
                />
              </v-col>
              <v-col cols="11">
                <p
                  class="main-text"
                  style="font-size: 16px"
                  @click="() => addReminder = true"
                >
                  {{ form.reminder ? form.reminder.description : 'Adicionar lembrete' }}
                </p>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row :class="detailOnly ? 'mt-8' : ''" v-if="step === steps.confirm">
      <v-col v-if="!realizeType" class="pt-0" cols="12">
        <p class="main-title" style="font-size: 32px">
          Confirme os dados para registrar
        </p>
      </v-col>

      <v-col v-if="realizeType" class="pt-0" cols="12">
        <p class="main-text mb-0">
          Exame
        </p>
        <p class="main-title" style="font-size: 32px">
          {{ specialityName }}
        </p>
        <v-chip
          color="#F8F8FF"
          style="color: var(--v-primary)"
        >
          {{ getExamTypeText() }}
        </v-chip>
        <p class="main-strong-text mb-0 mt-16" style="font-size: 20px; color: var(--v-gray2)" @click="realizeExam()">
          Detalhes do exame
        </p>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col v-if="!realizeType" cols="12">
            <v-text-field
              @click="() => examSpeciality = true"
              :value="specialityName"
              readonly
              placeholder="Exame"
            />
          </v-col>
          <v-col v-if="!realizeType" cols="12">
            <p class="main-strong-text" style="font-size: 16px">Tipo</p>
            <v-chip
              color="primary"
            >
              {{ getExamTypeText() }}
            </v-chip>
          </v-col>
          <v-col cols="12">
            <v-text-field
              class="mb-2"
              label="Nome do laboratório"
              v-model="form.laboratory.laboratoryName"
            />
            <v-text-field
              class="mb-2"
              label="CNPJ"
              v-mask="['##.###.###/####-##']"
              :rules="[...cnpjLen]"
              v-model="form.laboratory.laboratoryCnpj"
            />
            <v-text-field
              class="mb-2"
              label="Telefone"
              v-mask="['(##) ####-####', '(##) #####-####']"
              :rules="[...phoneMinLen]"
              v-model="form.laboratory.laboratoryPhone"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              @click="() => addDate = true"
              label="Data do exame"
              placeholder="Data do exame"
              readonly
              :value="formatDateInput"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              @click="() => addHour = true"
              label="Horário do exame"
              placeholder="Horário do exame"
              readonly
              v-model="form.hour"
            />
          </v-col>
          <v-col v-if="realizeType || form.fullfilled" cols="6">
            <v-text-field
              @click="() => addRealizationDate = true"
              label="Data de realização"
              placeholder="Data de realização"
              readonly
              :value="formatRealizationDateInput"
            />
          </v-col>
          <v-col v-if="realizeType || form.fullfilled" cols="6">
            <v-text-field
              @click="() => addRealizationHour = true"
              label="Horário de realização"
              placeholder="Horário de realização"
              readonly
              v-model="form.realizationHour"
            />
          </v-col>
          <v-col v-if="(!mapsCep && !form.place) || editAddress" cols="12">
            <google-map-autocomplete @input="upateAddress" placeholder="Digite um local"/>
          </v-col>
          <v-col v-if="!mapsCep && form.place && form.place.length > 0 && !editAddress" cols="12">
            <p style="font-size: 20px; color: #3F3F3F" class="main-strong-text">
              Local
            </p>
            <v-row class="mb-2">
              <v-col cols="10">
                <p
                  class="generic-text"
                  style="font-size: 16px"
                >
                  {{ form.place }}
                </p>
              </v-col>
              <v-col v-if="detailOnly && !fullfilledStatus" @click="() => editAddress = true" cols="2">
                <img
                  src="/img/icons/common/edit-pencil-pink.svg"
                  style="width: 25px; height: 25px;"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col class="pl-0 pr-0" cols="12" v-if="mapsCep">
            <p style="font-size: 20px; color: #3F3F3F" class="main-strong-text" @click="addLocal = false">
              Local
            </p>
            <googleMap
              :address="form.place"
              :zip-code="mapsCep"
              :get-city="getCity"
              :get-state="getState"
            />
            <p v-if="!exam" style="font-size: 14px; color: #3F3F3F" class="main-text mb-1 mt-2">
              {{ form.place }}{{ local.city.long_name }} - {{ local.state.short_name }}
            </p>
            <p v-if="!exam" style="font-size: 14px; color: #3F3F3F" class=" mb-0 main-text">
              {{ local.cep }}
            </p>
            <p v-if="exam" style="font-size: 14px; color: #3F3F3F" class="main-text mb-1 mt-2">
              {{ form.place }}
            </p>
          </v-col>
          <v-col cols="12" class="mt-6">
            <p style="font-size: 20px" class="main-subtitle">Alarme</p>
            <v-row class="mt-2 mb-2">
              <v-col cols="1">
                <img
                  src="/img/icons/common/bell-icon.svg"
                  style="width: 25px; height: 25px;"
                />
              </v-col>
              <v-col cols="11">
                <p
                  class="main-text"
                  style="font-size: 16px"
                  @click="() => addReminder = true"
                >
                  {{ form.reminder ? form.reminder.description : 'Adicionar lembrete' }}
                </p>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="pt-0" cols="12">
            <v-row class="mb-2">
              <v-col v-if="!fullfilledStatus" cols="1">
                <img
                  src="/img/appointments/clip-icon.svg"
                  style="width: 25px; height: 25px;"
                />
              </v-col>
              <v-col v-if="!fullfilledStatus" cols="11">
                <p
                  class="generic-text"
                  style="font-size: 16px"
                  @click="() => addAttach = true"
                >
                  Adicionar Anexo
                </p>
              </v-col>
              <v-col cols="12">
                <v-row>
                  <v-col class="pr-0" cols="4">
                    <v-chip
                      color="tertiary"
                      outlined
                      @click="() => showAttach = true"
                    >
                      Ver anexos
                    </v-chip>
                  </v-col>
                  <v-col class="pl-0" cols="8">
                    <v-chip
                      v-if="exam && exam.attachments && exam.attachments.length > 0"
                      outlined
                      color="primary"
                      @click="() => shareAttach()"
                      class="ml-5"
                    >
                      Compartilhar anexos
                    </v-chip>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="pt-0" cols="12">
            <p style="font-size: 20px" class="main-subtitle">Acesso aos resultados</p>
            <v-row class="mb-2">
              <v-col cols="1">
                <img
                  src="/img/appointments/clip-icon.svg"
                  style="width: 25px; height: 25px;"
                />
              </v-col>
              <v-col cols="11">
                <p
                  class="generic-text"
                  style="font-size: 16px"
                  @click="() => shareLab = true"
                >
                  Ver login e senha
                </p>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-card style="overflow: scroll" v-if="examSpeciality && !fullfilledStatus" class="medical-speciality">
      <v-container class="pa-10">
        <v-text-field
          style="background-color: white"
          placeholder="Buscar"
          v-model="search"
          @change="() => filterSpecialities()"
        >
          <img
            slot="append"
            src="/img/icons/common/search-red.svg"
            style="width: 20px; height: 20px"
            @click="() => filterSpecialities()"
          />
        </v-text-field>
        <v-text-field
            v-if="form.examSpeciality.description == 'Outros'"
            placeholder="Adicione um comentário"
            v-model="form.comment"
            @change="() => examSpeciality = false"
        ><img
            slot="append"
            src="/img/icons/common/plus-icon-pink.svg"
            style="width: 20px; height: 20px"
            @click="() => examSpeciality = false"
        />
        </v-text-field>
        <v-card elevation="0" style="overflow: auto;background-color: white;height: 59vh">
          <p
            v-for="speciality in specialities"
            :key="speciality.id"
            class="main-text mt-4 mb-8"
            style="font-size: 16px"
            @click="speciality.description != 'Outros' ? closeSpeciality(speciality) : form.examSpeciality = speciality"
          >
            {{ speciality && speciality.description }}
          </p>
        </v-card>
      </v-container>
    </v-card>
    <v-card elevation="16" v-if="addDate && !fullfilledStatus" class="medical-speciality">
      <v-container class="pa-5 pt-6">
        <v-row class="mb-0">
          <v-col class="pt-0" cols="10">
            <p style="font-size: 20px" class="main-strong-text mb-0 pt-2">
              Data do exame
            </p>
          </v-col>
          <v-col class="pt-0" cols="2">
            <img @click="() => addDate = false" src="/img/icons/common/close-icon.svg" style="height: 35px;" />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="timer" cols="12">
            <v-date-picker
              flat
              full-width
              v-model="form.date"
              color="#706CE0"
              no-title
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="timer">
            <generic-btn
              style="width: 100%; margin-top: 5%"
              :btn-props="{}"
              :on-click="() => addDate = false"
            >
              Salvar
            </generic-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-card elevation="16" style="height: 90%; top: 15%" v-if="addHour && !fullfilledStatus" class="medical-speciality">
      <v-container class="pa-5 pt-6">
        <v-row class="mb-0">
          <v-col class="pt-0" cols="10">
            <p style="font-size: 20px" class="main-strong-text mb-0 pt-2">
              Horário
            </p>
          </v-col>
          <v-col class="pt-0" cols="2">
            <img @click="() => addHour = false" src="/img/icons/common/close-icon.svg" style="height: 35px;" />
          </v-col>
        </v-row>
        <v-row>
          <v-col style="margin-top: -51px" cols="12">
            <v-time-picker
              format="24hr"
              full-width
              v-model="form.hour"
              color="tertiary"
              header-color="white"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col style="margin-top: -43px">
            <generic-btn
              style="width: 100%; margin-top: 5%"
              :btn-props="{}"
              :on-click="() => addHour = false"
            >
              Salvar
            </generic-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-card elevation="16" v-if="addRealizationDate" class="medical-speciality">
      <v-container class="pa-5 pt-6">
        <v-row class="mb-0">
          <v-col class="pt-0" cols="10">
            <p style="font-size: 20px" class="main-strong-text mb-0 pt-2">
              Data de realização
            </p>
          </v-col>
          <v-col class="pt-0" cols="2">
            <img @click="() => addRealizationDate = false" src="/img/icons/common/close-icon.svg" style="height: 35px;" />
          </v-col>
        </v-row>

        <v-date-picker
          flat
          full-width
          v-model="form.realizationDate"
          color="#706CE0"
          no-title
          :max="moment().format('YYYY-MM-DD')"
        />

        <generic-btn
            style="width: 100%; margin-top: 5%"
            :btn-props="{}"
            :on-click="() => addRealizationDate = false"
        >
          Salvar
        </generic-btn>
      </v-container>
    </v-card>
    <v-card elevation="16" style="height: 90%; top: 15%" v-if="addRealizationHour && !fullfilledStatus" class="medical-speciality">
      <v-container class="pa-5 pt-6">
        <v-row class="mb-0">
          <v-col class="pt-0" cols="10">
            <p style="font-size: 20px" class="main-strong-text mb-0 pt-2">
              Horário de realização
            </p>
          </v-col>
          <v-col class="pt-0" cols="2">
            <img @click="() => addRealizationHour = false" src="/img/icons/common/close-icon.svg" style="height: 35px;" />
          </v-col>
        </v-row>

        <v-time-picker
            format="24hr"
            full-width
            v-model="form.realizationHour"
            color="tertiary"
            header-color="white"
        />

        <generic-btn
            style="width: 100%; margin-top: 5%"
            :btn-props="{}"
            :on-click="() => addRealizationHour = false"
        >
          Salvar
        </generic-btn>
      </v-container>
    </v-card>
    <v-card
      elevation="16"
      :style="{
        height: form.attachments.length > 0 ? '90%' : '25%',
        top: form.attachments.length > 0 ? '15%' : '80%'
      }"
      v-if="showAttach"
      class="medical-speciality"
    >
      <v-container class="pa-5 pt-6">
        <v-row class="mb-0">
          <v-col class="pt-0" cols="10">
            <p style="font-size: 20px" class="main-strong-text mb-0 pt-2">
              Anexos
            </p>
          </v-col>
          <v-col class="pt-0" cols="2">
            <img @click="() => showAttach = false" src="/img/icons/common/close-icon.svg" style="height: 35px;" />
          </v-col>
        </v-row>

        <v-list v-if="form.attachments.length > 0">
          <v-list-item v-for="attach in form.attachments" :key="attach.fileName" two-line>
            <v-list-item-content>
              <v-list-item-title style="font-size: 20px">{{ attach.attachmentType ? attach.attachmentType.name : 'Arquivo'}}</v-list-item-title>
              <v-list-item-subtitle style="font-size: 20px">
                <a style="color: gray;" :href="`data:${getAttachmentType(attach)};base64,${attach.fileContent ? attach.fileContent : ''}`"
                   :download="getAttachmentDownload(attach)">
                  {{ attach.fileName ? attach.fileName : '' }}
                </a>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <img @click="deleteAttach(attach)" src="/img/icons/common/close-icon.svg" style="height: 30px;" />
            </v-list-item-action>
          </v-list-item>
        </v-list>
        <p v-else class="generic-text text-center">Sem anexos adicionados</p>
      </v-container>
    </v-card>
    <v-card elevation="16" style="height: 60%; top: 45%" v-if="shareLab" class="medical-speciality">
      <v-container class="pa-5 pt-6">
        <v-row class="mb-0">
          <v-col class="pt-0" cols="10">
            <p style="font-size: 20px" class="main-strong-text mb-0 pt-2">
              Acesso do laboratório
            </p>
          </v-col>
          <v-col class="pt-0" cols="2">
            <img @click="() => shareLab = false" src="/img/icons/common/close-icon.svg" style="height: 35px;" />
          </v-col>
        </v-row>

        <div class="mt-6" v-if="link">
          <p class="main-title text-center pl-6 pr-8">
            Para efetuar o compartilhamento faça o acesso pelo botão
          </p>

          <generic-btn
            style="width: 90%; margin-top: 7%"
            class="ml-5"
            :btn-props="{ href: link }"
            :on-click="() => {}"
          >
            Download dos anexos
          </generic-btn>
        </div>

        <v-list v-if="!link">
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title style="font-size: 20px">
                Login
              </v-list-item-title>
              <v-list-item-subtitle style="font-size: 20px">
                {{ exam.resultUser || 'Login não informado' }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title style="font-size: 20px">
                Senha
              </v-list-item-title>
              <v-list-item-subtitle style="font-size: 20px">
                {{ exam.resultPassword || 'Senha não informada' }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <generic-btn
          v-if="!link"
          style="width: 100%; margin-top: 15%"
          :btn-props="{}"
          :on-click="() => accTerms = true"
        >
          Compartilhar
        </generic-btn>
      </v-container>
    </v-card>
    <v-card elevation="16" style="height: 85%; top: 20%" v-if="addReminder && !fullfilledStatus" class="medical-speciality">
      <v-container class="pa-5 pt-6">
        <v-row class="mb-0">
          <v-col class="pt-0" cols="10">
            <p style="font-size: 20px" class="main-strong-text mb-0 pt-2">
              Alarme
            </p>
          </v-col>
          <v-col class="pt-0" cols="2">
            <img @click="() => addReminder = false" src="/img/icons/common/close-icon.svg" style="height: 35px;" />
          </v-col>
        </v-row>

        <div style="height: 350px" class="scroll-hidden">
          <v-row
            :style="form.reminder && form.reminder.id === alert.id ?
              { backgroundColor: '#F8F8FF' } : {}
            "
            v-for="alert in reminders"
            :key="alert.id"
            class="pt-1"
            @click="() => form.reminder = alert"
          >
            <v-col class="pb-0" cols="10">
              <p
                class="main-text"
                style="font-size: 16px"
              >
                {{ alert.description }}
              </p>
            </v-col>

            <v-col v-if="form.reminder && form.reminder.id === alert.id" class="pb-0 pt-3" cols="2">
              <img
                src="/img/appointments/check-icon.svg"
                style="width: 20px; height: 20px; margin-top: 2px"
              />
            </v-col>
          </v-row>
        </div>

        <generic-btn
          style="width: 100%; margin-top: 5%"
          :btn-props="{}"
          :on-click="() => addReminder = false"
        >
          Salvar
        </generic-btn>
      </v-container>
    </v-card>

    <label v-show="false" id="fileUpload" ref="fileUpload" for="file">
      <input
        type="file"
        ref="file"
        @change="updateFiles"
        v-show="false"
      />
    </label>

    <v-card elevation="16" style="height: 85%; top: 20%" v-if="addAttach && !fullfilledStatus" class="medical-speciality">
      <v-container class="pa-5 pt-6">
        <v-row class="mb-0">
          <v-col class="pt-0" cols="10">
            <p style="font-size: 20px" class="main-strong-text mb-0 pt-2">
              Anexos
            </p>
          </v-col>
          <v-col class="pt-0" cols="2">
            <img @click="() => addAttach = false" src="/img/icons/common/close-icon.svg" style="height: 35px;" />
          </v-col>
        </v-row>

        <div style="height: 350px" class="scroll-hidden">
          <v-row
            :style="currentAttachType === type ?
              { backgroundColor: '#F8F8FF' } : {}
            "
            v-for="type in attachTypes"
            :key="type.id"
            class="pt-1"
            @click="() => currentAttachType = type"
          >
            <v-col class="pb-0" cols="10">
              <p
                class="main-text"
                style="font-size: 16px"
              >
                {{ type.name }}
              </p>
            </v-col>

            <v-col
              v-if="currentAttachType === type"
              class="pb-0 pt-3"
              cols="2"
            >
              <img
                src="/img/appointments/check-icon.svg"
                style="width: 20px; height: 20px; margin-top: 2px"
              />
            </v-col>
          </v-row>
        </div>

        <generic-btn
          style="width: 100%; margin-top: 5%"
          :btn-props="{}"
          :on-click="triggerClick"
        >
          Adicionar
        </generic-btn>
      </v-container>
    </v-card>
    <v-card elevation="16" v-if="addLocal && !fullfilledStatus" class="medical-speciality">
      <v-container class="pa-10 pt-6">
        <v-row class="mb-0">
          <v-col class="pt-0" cols="10">
            <p style="font-size: 20px" class="main-strong-text mb-0 pt-2">
              Local
            </p>
          </v-col>
          <v-col class="pt-0" cols="2">
            <img @click="() => addLocal = false" src="/img/icons/common/close-icon.svg" style="height: 35px;" />
          </v-col>
        </v-row>

        <v-text-field
          label="CEP"
          v-model="local.cep"
          v-mask="'#####-###'"
        />

        <v-text-field
          label="Bairro"
          v-model="local.neighbour"
        />

        <v-text-field
          label="Rua"
          v-model="local.address"
        />

        <v-text-field
          label="Nº"
          :rules="local.number && numberOnly"
          v-model="local.number"
        />

        <generic-btn
          style="width: 100%; margin-top: 5%"
          :btn-props="{ disabled: disableLocalForm }"
          :on-click="saveAddress"
        >
          Salvar
        </generic-btn>
      </v-container>
    </v-card>
    <v-form ref="doctorForm">
      <v-card elevation="16" v-if="newLaboratory" class="medical-speciality">
        <v-container class="pa-10 pt-6">
          <v-row class="mb-8">
            <v-col class="pt-0" cols="10">
              <p style="font-size: 20px" class="main-strong-text mb-0 pt-2">
                Adicionar laboratório
              </p>
            </v-col>
            <v-col class="pt-0" cols="2">
              <img @click="() => newLaboratory = false" src="/img/icons/common/close-icon.svg" style="height: 35px;" />
            </v-col>
          </v-row>
          <v-text-field
            class="mb-2"
            label="Nome do laboratório"
            v-model="addLaboratory.laboratoryName"
            :rules="required"
          />
          <v-text-field
            class="mb-2"
            label="CNPJ"
            v-mask="['##.###.###/####-##']"
            :rules="[...cnpjLen]"
            v-model="addLaboratory.laboratoryCnpj"
          />
          <v-text-field
            class="mb-2"
            label="Telefone"
            v-mask="['(##) ####-####', '(##) #####-####']"
            v-model="addLaboratory.laboratoryPhone"
            :rules="[...phoneMinLen]"
          />

          <generic-btn
            style="width: 100%; margin-top: 20%"
            :btn-props="{}"
            :on-click="saveLab"
          >
            Salvar
          </generic-btn>
        </v-container>
      </v-card>
    </v-form>
    <div v-if="overlayOn && !fullfilledStatus" class="card-overlay"/>
    <v-layout v-if="!fullfilledStatus && !shareAttachOn" justify-center align-end>
      <v-card style="bottom: -20px; height: 30%" elevation="16" class="step-btn">
        <generic-btn
          style="width: 90%; margin-top: 7%"
          class="ml-5"
          :btn-props="{ disabled: disableSave || !this.form.realizationDate || !this.form.realizationHour }"
          :on-click="() => realizeExam()"
          v-if="step === steps.confirm && realizeType && !detailOnly"
        >
          Comparecer
        </generic-btn>
        <generic-btn
          style="width: 90%; margin-top: 8%"
          class="ml-5"
          :btn-props="{ disabled: disableSave || !this.form.realizationDate || !this.form.realizationHour }"
          :on-click="() => updateExam()"
          v-if="step === steps.confirm && detailOnly"
        >
          Alterar
        </generic-btn>
        <generic-btn
          style="width: 90%; margin-top: 7%"
          class="ml-5"
          :btn-props="{ disabled: !this.form.examSpeciality.description || !this.form.examType.code }"
          :on-click="() => step++"
          v-if="step === steps.exam && !realizeType"
        >
          Próximo
        </generic-btn>
        <generic-btn
          style="margin-top: 7%; margin-left: 8%; width: 40%"
          :btn-props="{ outlined: true }"
          :on-click="() => step--"
          v-if="step !== steps.exam && !realizeType"
        >
          Voltar
        </generic-btn>
        <generic-btn
          style="margin-top: 7%; width: 40%"
          class="ml-5"
          :btn-props="{ disabled: disableNext }"
          :on-click="() => step++"
          v-if="step !== steps.exam && step !== steps.confirm && !realizeType"
        >
          Próximo
        </generic-btn>
        <generic-btn
          style="margin-top: 7%; width: 40%"
          class="ml-5"
          :btn-props="{ disabled: disableSave }"
          :on-click="() => saveExam()"
          v-if="step === steps.confirm && !realizeType"
        >
          Registrar
        </generic-btn>
      </v-card>
    </v-layout>
    <shareWarn :acc-term="accTerms" :btn-action="toggleDialog" />
  </v-container>
</template>

<script>
import {examStatus, examTypes, regexValidations} from '../../common/constants';
import ExamTypeService from '@/services/healthcare/ExamTypeService';
import ExamEventService from "@/services/healthcare/ExamEventService";
import ReminderService from '@/services/healthcare/ReminderService';
import AttachmentTypeService from '@/services/healthcare/AttachmentTypeService'
import ExamSpecialityService from '@/services/healthcare/ExamSpecialityService'
import AccountService from "@/services/contract/AccountService";
import { mapMutations } from 'vuex';
import moment from 'moment';
import shareWarn from "./shareWarn";
// import ExamFileUpload from "../../components/generic-upload/ExamFileUpload";

import googleMap from '../google-maps/google-map';
import backArrow from '../../components/covid/back-arrow';
import GoogleMapAutocomplete from '../../components/google-maps/google-map-autocomplete';
import Vue from 'vue'

export default {
  name: 'ExamRegister',
  components: {
    googleMap,
    shareWarn,
    backArrow,
    GoogleMapAutocomplete
  },
  props: {
    exam: {
      type: Object,
      required: false
    },
    clearExam: {
      type: Function,
      required: true
    },
    close: {
      required: true,
      type: Function
    },
    success: {
      required: false,
      type: Function
    },
    shareAttach: {
      required: false,
      type: Function
    },
    shareAttachOn: {
      required: true,
      type: Boolean
    },
    realizeType: {
      type: Boolean,
      default: false
    },
    detailOnly: {
      type: Boolean,
      default: false
    },
    refreshExams: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      moment,
      examTypes,
      editAddress: false,
      accTerms: false,
      currentAttachType: null,
      newLaboratory: false,
      addDate: false,
      addRealizationDate: false,
      addHour: false,
      addRealizationHour: false,
      addLocal: false,
      addReminder: false,
      addAttach: false,
      showAttach: false,
      shareLab: false,
      step: 1,
      steps: {
        exam: 1,
        laboratory: 2,
        date: 3,
        confirm: 4
      },
      examSpeciality: false,
      addLaboratory: {
        laboratoryName: null,
        laboratoryPhone: null,
        laboratoryCnpj: null,
        laboratoryId: null
      },
      local: {
        cep: null,
        neighbour: '',
        address: '',
        number: '',
        city: '',
        state: ''
      },
      mapsCep: null,
      form: {
        fullfilled: false,
        examType: { code: null },
        examSpeciality: { description: null },
        laboratory: { laboratoryName: null },
        date: null,
        realizationDate: null,
        hour: null,
        realizationHour: null,
        place: null,
        reminder: null,
        attachments: [],
        userId: null,
        comment: null,
      },
      allSpecialities: [],
      specialities: [],
      allLaboratoriesHistory: [],
      laboratoryHistory: [],
      allLaboratories: [],
      laboratory: [],
      reminders: [],
      types: [],
      attachTypes: [],
      search: '',
      searchLaboratory: '',
      link: null,
      numberOnly: [
        value => {
          const pattern = /^\d*$/;
          return value && (pattern.test(value) || 'Precisa ser um número');
        },
      ],
      required: [
        value => !!value || 'Campo obrigatório',
      ],
      phoneMinLen: [
        value => !value || (value.length >= 14 || value.length <= 1 ? true : 'Mínimo 8 números + DDD'),
      ],
      cnpjLen: [
        value => !value || (value.length === 18 || 'Precisa ter 14 números'),
      ]
    }
  },
  watch: {
    'form.fullfilled'(newValue) {
      if(newValue){
        this.form.realizationDate = moment().format('YYYY-MM-DD');
        this.form.realizationHour = moment().format('HH:mm');
      }else{
        this.form.realizationDate = null;
        this.form.realizationHour = null;
      }
    },
    search() {
      this.filterSpecialities()
    },
    searchLaboratory() {
      this.filterLaboratoriesHistory()
    }
  },
  methods: {
    ...mapMutations(['showmsg', 'loading']),
    upateAddress (data) {
      const {
        code,
        neighborhood,
        region,
        city,
        log,
        number
      } = data

      this.local.cep = code
      this.local.neighbour = neighborhood
      this.local.address = log
      this.local.city = city
      this.local.state = region
      this.local.number = number

      // Enable Form to finish address register
      this.addLocal = true
    },
    updateExamTotalizers(){
      this.$emit('updateExamTotalizers');
    },
    verifyIsRealized(){
      this.$emit('verifyIsRealized');
    },
    toggleDialog() {
      this.shareAccess();
      this.accTerms = false;
    },
    saveLab() {
      let valid = this.$refs.doctorForm.validate();

      if(valid) {
        Vue.set(this.form, 'laboratory', this.addLaboratory)
        this.newLaboratory = false;
      }
    },
    readFileContent(file) {
      const fileReader = new FileReader();

      return new Promise((resolve, reject) => {
        fileReader.onerror = () => {
          fileReader.abort();
          reject(new DOMException("Unable to parse the file."));
        };

        fileReader.onload = () => resolve({
          name: file.name,
          body: fileReader.result,
          size: file.size,
        });

        fileReader.readAsDataURL(file);
      });
    },
    async updateFiles(files) {

      const file = await Promise.resolve(this.readFileContent(files.target.files[0]));

      this.form.attachments.push({
        fileName: file.name.substring(0, file.name.lastIndexOf(".")) || "",
        contentType: file.name.substring(file.name.lastIndexOf("."), file.length) || "",
        fileContent: file.body.split(",")[1],
        attachmentType: this.currentAttachType
      });
    },
    triggerClick() {
      this.$refs.file.click();
      this.addAttach = false;
    },
    deleteAttach(attach) {
      let remove = this.form.attachments.findIndex((file) => attach === file);
      this.form.attachments.splice(remove, 1)
    },
    getExamTypes(type) {
      this.types.filter(t => {
        if(t.code === type) this.form.examType = t;
      });
    },
    getExamTypeText() {
      let type = this.form.examType.code;

      if(type === examTypes.self)
        return 'Iniciativa própria'

      if(type === examTypes.suggestion)
        return 'Protocolo Eu Protegido'

      if(type === examTypes.refer)
        return 'Indicação médica'
    },
    laboratoryNotFound() {
      return this.laboratory.length === 0 &&
        this.laboratoryHistory.length === 0 &&
        this.searchLaboratory
    },
    closeSpeciality(speciality) {
      this.form.examSpeciality = speciality;
      this.examSpeciality = false;
    },
    getCity(city) {
      this.local.city = city;
    },
    getState(state) {
      this.local.state = state;
    },
    saveAddress() {
      let { address, number, cep, neighbour } = this.local;

      if(cep) {
        this.mapsCep = cep;

        if(neighbour)
          this.form.place = `${address}, ${number}, ${neighbour}/`;
        else this.form.place = `${address}, ${number}/`;
      }else {
        if(neighbour) this.form.place = `${address}, ${number}, ${neighbour}`;
        else this.form.place = `${address}, ${number}`
      }

      this.addLocal = false;
      this.editAddress = false;
    },
    getSpecialities() {
      this.loading(true);

      this._examSpecialityService.getAll()
        .then((res) => {
          this.specialities = res.data;
          this.allSpecialities = this.specialities;
        })
        .catch(() =>
          this.showmsg({
            text: "Erro ao carregar os exames!",
            type: "error",
          })
        )
        .finally(() => this.loading(false));
    },
    getReminders() {
      this.loading(true);

      this._reminderService.getAll()
        .then((res) => {
          this.reminders = res.data;
        })
        .catch(() =>
          this.showmsg({
            text: "Erro ao carregar alertas!",
            type: "error",
          })
        )
        .finally(() => this.loading(false));
    },
    getTypes() {
      this.loading(true);

      this._examTypeService.getAll()
        .then((res) => {
          this.types = res.data;
        })
        .catch(() =>
          this.showmsg({
            text: "Erro ao carregar tipos de exame!",
            type: "error",
          })
        )
        .finally(() => this.loading(false));
    },
    getLaboratory() {
      this.loading(true);

      this._accountService.findAllLaboratoryByName(this.searchLaboratory)
        .then((res) => {
          this.laboratory = res.data;
          this.allLaboratories = this.laboratory;
        })
        .catch(() =>
          this.showmsg({
            text: "Erro ao carregar os laboratórios!",
            type: "error",
          })
        )
        .finally(() => this.loading(false));
    },
    async getLaboratoryHistory() {
      this.loading(true);

      await this._examEventService.findLaboratoriesHistory()
        .then((res) => {
          this.laboratoryHistory = res.data;
          this.allLaboratoriesHistory = this.laboratoryHistory;
        })
        .catch(() =>
          this.showmsg({
            text: "Erro ao carregar históricode laboratórios!",
            type: "error",
          })
        )
        .finally(() => this.loading(false));
      await this._accountService.findAllMainLaboratories()
          .then((res) => {
            let laboratories = res.data;
            for(let laboratory of laboratories){
              let mainLaboratory = {}
              mainLaboratory.laboratoryCnpj = laboratory.cnpj ? laboratory.cnpj : ''
              mainLaboratory.laboratoryId = laboratory.id ? laboratory.id : ''
              mainLaboratory.laboratoryName = laboratory.name ? laboratory.name : ''
              mainLaboratory.laboratoryPhone = laboratory.phone ? laboratory.phone : ''
              this.laboratoryHistory.push(mainLaboratory)
            }
          })
          .catch(() =>
              this.showmsg({
                text: "Erro ao carregar histórico dos laboratórios!",
                type: "error",
              })
          )
          .finally(() => this.loading(false));
    },
    getAttachTypes() {
      this.loading(true);

      this._attachmentTypeService.findAllExamGroup()
        .then((res) => {
          this.attachTypes = res.data;
        })
        .catch(() =>
          this.showmsg({
            text: "Erro ao carregar tipos de anexos!",
            type: "error",
          })
        )
        .finally(() => this.loading(false));
    },
    filterSpecialities() {
      if(this.search) {
        let filtered = this.allSpecialities.filter((article) => {
          let query = this.search.toLowerCase();
          let name = article.description && article.description.toLowerCase();

          return name && name.indexOf(query) > -1
        });

        this.specialities = filtered;
      }else {
        this.specialities = this.allSpecialities;
      }
    },
    filterLaboratoriesHistory() {
      if(this.searchLaboratory) {
        let filtered = this.allLaboratoriesHistory.filter((article) => {
          let query = this.searchLaboratory.toLowerCase();
          let name = article.laboratoryName && article.laboratoryName.toLowerCase();

          return name && name.indexOf(query) > -1
        });
        this.laboratoryHistory = filtered;
        this.getLaboratory()
      }else {
        this.laboratoryHistory = this.allLaboratoriesHistory;
      }
    },
    saveExam() {
      this.loading(true);

      let date = moment(`${this.form.date} ${this.form.hour}`).format('YYYY-MM-DD[T]HH:mm:SS[.000Z]');
      let parseRealizationDate = null;

      if(this.form.realizationDate && this.form.realizationHour) {
        parseRealizationDate = moment(`${this.form.realizationDate} ${this.form.realizationHour}`).format('YYYY-MM-DD[T]HH:mm:SS[.000Z]');
      }

      let address = `${ this.form.place }`
      let placeCep = this.exam && this.exam.place && this.exam.place.match(regexValidations.cep);

      if(this.form.realizationDate && this.form.realizationHour) {
        parseRealizationDate = moment(`${this.form.realizationDate} ${this.form.realizationHour}`).format('YYYY-MM-DD[T]HH:mm:SS[.000Z]');
      }

      if(!placeCep)
        if(this.local.city && this.local.state && this.local.cep)
          address = `${ this.form.place } ${ this.local.city.long_name } - ${ this.local.state.short_name } / ${this.local.cep}`

      let data = {
        ...this.form,
        ...this.form.laboratory,
        scheduledDate: date,
        status: 'AGENDADO',
        realizationDate: this.form.fullfilled ? parseRealizationDate : null,
        place: address
      }

      this._examEventService.create(data)
        .then(() => {
          this.loading(false);
          this.close();
          this.success();
          this.updateExamTotalizers();
          if(this.form.fullfilled === true){
            this.verifyIsRealized();
          }
        })
        .catch(() =>
          this.showmsg({
            text: "Erro ao salvar o exame!",
            type: "error",
          })
        )
        .finally(() => {
          this.loading(false);
          this.clearExam();
          this.refreshExams();
        });
    },
    updateExam() {
      this.loading(true);

      let date = moment(`${this.form.date} ${this.form.hour}`).format('YYYY-MM-DD[T]HH:mm:SS[.000Z]');
      let parseRealizationDate = null;

      if(this.form.realizationDate && this.form.realizationHour) {
        parseRealizationDate = moment(`${this.form.realizationDate} ${this.form.realizationHour}`).format('YYYY-MM-DD[T]HH:mm:SS[.000Z]');
      }

      let address = `${ this.form.place }`
      let placeCep = this.exam && this.exam.place && this.exam.place.match(regexValidations.cep);

      if(this.form.realizationDate && this.form.realizationHour) {
        parseRealizationDate = moment(`${this.form.realizationDate} ${this.form.realizationHour}`).format('YYYY-MM-DD[T]HH:mm:SS[.000Z]');
      }

      if(!placeCep)
        if(this.local.city && this.local.state && this.local.cep)
          address = `${ this.form.place } ${ this.local.city.long_name } - ${ this.local.state.short_name } / ${this.local.cep}`

      let data = {
        ...this.form,
        ...this.form.laboratory,
        scheduledDate: date,
        status: 'AGENDADO',
        realizationDate: this.form.fullfilled ? parseRealizationDate : null,
        place: address
      }

      this._examEventService.update(this.exam.id, data)
        .then(() => {
          this.loading(false);
          this.close();
          this.success();
          this.updateExamTotalizers();
        })
        .catch(() =>
          this.showmsg({
            text: "Erro ao salvar o exame!",
            type: "error",
          })
        )
        .finally(() => {
          this.loading(false);
          this.clearExam();
          this.refreshExams();
        });
    },
    shareAccess() {
      this.loading(true);

      this._examEventService.SharingExamLaboratory([this.exam.id])
        .then(({ data }) => {
          this.link = data.link
        })
        .catch(() =>
          this.showmsg({
            text: "Erro ao compartilhar!",
            type: "error",
          })
        )
        .finally(() => {
          this.loading(false);
          this.shareLab = false;
        });
    },
    realizeExam() {
      this.loading(true);

      let parseRealizationDate = moment(`${this.form.realizationDate} ${this.form.realizationHour}`).format('YYYY-MM-DD[T]HH:mm:SS[.000Z]');

      let address = `${ this.form.place }`
      let placeCep = this.exam && this.exam.place && this.exam.place.match(regexValidations.cep);

      if(this.form.realizationDate && this.form.realizationHour) {
        parseRealizationDate = moment(`${this.form.realizationDate} ${this.form.realizationHour}`).format('YYYY-MM-DD[T]HH:mm:SS[.000Z]');
      }

      if(!placeCep)
        if(this.local.city && this.local.state && this.local.cep)
          address = `${ this.form.place } ${ this.local.city.long_name } - ${ this.local.state.short_name } / ${this.local.cep}`

      let data = {
        ...this.form.laboratory,
        id: this.form.id,
        userId: this.form.userId,
        realizationDate: parseRealizationDate,
        examSpeciality: this.form.examSpeciality,
        place: address,
        scheduledDate: this.exam.scheduledDate,
        comment: this.exam.comment,
        examType: { id: this.exam.examType.id },
        attachments: this.form.attachments,
      }

      this._examEventService.realizeExam(data)
        .then(() => {
          this.close();
          this.updateExamTotalizers();
        })
        .catch(() =>
          this.showmsg({
            text: "Erro ao registrar exame! Por favor preencha todos os campos.",
            type: "error",
          })
        )
        .finally(() => {
          this.clearExam();
          this.refreshExams();
          this.loading(false);
        });
  },
    getAttachmentType(attach){
      return attach.contentType ? attach.contentType.replaceAll('.', '') : ''
    },
    getAttachmentDownload(attach){
      return attach.fileName && attach.contentType ? (attach.fileName + attach.contentType) : ''
    }
  },
  computed: {
    fullfilledStatus() {
      if(this.exam){
        if(this.exam.status === examStatus.fullfilled)
          return true
        else return false
      }
      else return false
    },
    specialityName() {
      if(this.form.examSpeciality && this.form.examSpeciality.description === 'Outros'){
        return `${this.form.examSpeciality.description}  ${this.form.comment == null ? '' : '- ' + this.form.comment}`
      }else{
        return this.form.examSpeciality && this.form.examSpeciality.description
      }
    },
    overlayOn() {
      return this.newLaboratory ||
        this.addLocal ||
        this.addDate ||
        this.addHour ||
        this.addReminder ||
        this.addAttach ||
        this.showAttach ||
        this.examSpeciality ||
        this.shareLab
    },
    containerHeight() {
      if(this.step === this.steps.date)
        return { height: '90vh' };

      if(this.step === this.steps.confirm)
        return { height: '100vh' };

      else return { height: '90vh' };
    },
    disableNext() {
      if(this.step === this.steps.laboratory)
        return !this.form.laboratory.laboratoryName;

      if(this.step === this.steps.date)
        return !this.form.date ||
          !this.form.hour ||
          !this.local.address ||
          !this.local.number

      else return false
    },
    disableLocalForm() {
      return !this.local.address || !this.local.number;
    },
    formatDateInput() {
      return this.form.date ? moment(this.form.date).format('DD/MM/YYYY') : '';
    },
    formatRealizationDateInput() {
      return this.form.realizationDate ? moment(this.form.realizationDate).format('DD/MM/YYYY') : '';
    },
    laboratoryValidation() {
      return !this.addLaboratory.laboratoryName
    },
    disableSave() {
      return !this.form.examSpeciality ||
        !this.form.laboratory.laboratoryName ||
        !this.form.date ||
        !this.form.hour ||
        !this.form.place
    }
  },
  beforeCreate() {
    this._examTypeService = new ExamTypeService();
    this._examSpecialityService = new ExamSpecialityService();
    this._examEventService = new ExamEventService();
    this._reminderService = new ReminderService();
    this._attachmentTypeService = new AttachmentTypeService();
    this._accountService = new AccountService();
  },
  mounted() {
    if(window) {
      window.scrollTo(0, 0);
    }

    this.getSpecialities();
    this.getLaboratoryHistory();
    this.getReminders();
    this.getTypes();
    this.getAttachTypes();

    let user = this.$auth.user();
    this.form.userId = user.id;

    if(this.realizeType) {
      this.step = this.steps.confirm;
    }

    if(this.exam) {
      let cep = this.exam.place && this.exam.place.match(regexValidations.cep);

      if(cep && cep[0]) {
        this.mapsCep = cep[0];
        this.local.cep = cep[0];
      }

      this.addLaboratory = {
        laboratoryName: this.exam.laboratoryName,
        laboratoryCnpj: this.exam.laboratoryCnpj,
        laboratoryId: this.exam.laboratoryId,
        laboratoryPhone: this.exam.laboratoryPhone,
      }
      let actualDate = null
      let actualHour = null
      if(!this.detailOnly) {
        actualDate = this.exam.realizationDate ?
            moment(this.exam.realizationDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
        actualHour = this.exam.realizationDate ?
            moment(this.exam.realizationDate).format("HH:mm") : moment().format("HH:mm");
      }else{
        actualDate = this.exam.realizationDate ?
            moment(this.exam.realizationDate).format('YYYY-MM-DD') : null;
        actualHour = this.exam.realizationDate ?
            moment(this.exam.realizationDate).format("HH:mm") : null;
      }

      this.form = {
        ...this.exam,
        attachments: this.exam.attachments ? this.exam.attachments : [],
        realizationDate: actualDate,
        realizationHour: actualHour,
        fullfilled: false,
        laboratory: {
          laboratoryName: this.exam.laboratoryName,
          laboratoryCnpj: this.exam.laboratoryCnpj,
          laboratoryId: this.exam.laboratoryId,
          laboratoryPhone: this.exam.laboratoryPhone,
        },
        date: this.exam.scheduledDate ? moment(this.exam.scheduledDate).format('YYYY-MM-DD') : null,
        hour: this.exam.scheduledDate ? moment(this.exam.scheduledDate).format('HH:mm') : null,
        examType: this.exam.examType ? this.exam.examType : { code: null },
        examSpeciality: this.exam.examSpeciality ? this.exam.examSpeciality : { description: null },
      }
    }
  }
}
</script>

<style>
.v-time-picker-title {
  color: var(--v-tertiary) !important;
  justify-content: center !important;
}

.step-btn {
  border-top-left-radius: 40px !important;
  border-top-right-radius: 40px !important;
  width: 100vw;
  height: 129px;
  position: fixed;
  top: 85%;
  left: 0;
  z-index: 10;
}

.medical-speciality {
  border-top-left-radius: 40px !important;
  border-top-right-radius: 40px !important;
  width: 100vw;
  height: 75%;
  position: fixed;
  top: 30%;
  left: 0;
  bottom: 0;
  z-index: 20;
  -webkit-animation: medical-speciality 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: medical-speciality 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.card-overlay {
  width: 100vw;
  height: 200% !important;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 15;
  background: rgba(0, 0, 0, .6);
}

.scroll-hidden {
  overflow-y: scroll;
  overflow-x: hidden;
  white-space: nowrap;
}

@-webkit-keyframes medical-speciality {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}
@keyframes medical-speciality {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}

@media(max-width: 361px) {
  .timer{
    margin-top: -43px
  }

}
</style>
