<template>
  <v-container
    :style="{ height: '100%', display: 'block', width: '100vw', background: !register ? 'rgba(255, 255, 255, .5)' : 'white'}"
    class="pa-6 pt-2 fluid background-glass-effect"
  >
    <backArrow v-if="!register" />
    <v-layout class="mt-1" v-if="!register && !registerSuccess" align-end justify-end>
      <img  @click="$router.push('/principais-duvidas')" src="/img/nick/question-nick.svg" style="width: 55px"/>
    </v-layout>
    <v-layout v-if="!register && !registerSuccess" align-start justify-start>
      <v-row>
        <v-col cols="6" class="pb-0">
          <!--         <v-select-->
          <!--           :items="['aa', 'ss']"-->
          <!--           rounded-->
          <!--           background-color="#e4e7f8"-->
          <!--           append-icon="fas fa-chevron-down"-->
          <!--           height="35"-->
          <!--         />-->
        </v-col>
      </v-row>
    </v-layout>
    <Totalizer
      v-if="!register && !registerSuccess"
      :title="'Exames'"
      class="mb-2 pt-11"
      :totals="totals"
      :title-icon="titleIcon"
      color="#FE7B93"
      :isExam="true"
      :height="80"
    />

    <v-layout v-if="!register && !registerSuccess" justify-center align-center>
      <v-card
        style="background: rgba(231, 234, 254, .6);"
        class="option-list-card list-card mb-6"
      >
        <v-row justify="center">
          <v-col class="pl-6 pr-0 pt-5" cols="7">
            <div
              :class="!activeFilter ? 'list-card pt-4' : 'pb-1 pt-4'"
              :style="!activeFilter && 'background: white; height: 50px'"
            >
              <p
                class="text-center filter-text generic-text mb-0"
                @click="() => activeFilter = !activeFilter"
              >
                Próximas e Atrasadas
              </p>
            </div>
          </v-col>
          <v-col class="pl-0 pr-7 pt-5" cols="5">
            <div
              :class="activeFilter ? 'list-card pt-3' : 'pb-1 pt-3'"
              :style="activeFilter && 'background: white; height: 50px'"
            >
              <p
                class="text-center history-margin-adjust filter-text generic-text mb-0"
                @click="() => activeFilter = !activeFilter"
              >
                Histórico
              </p>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-layout>
    <v-layout v-if="!register && !registerSuccess">
      <v-row v-if="!activeFilter" justify="center">
        <v-col v-if="lateExams.length > 0" cols="12">
          <p style="font-weight: bolder" class="text-left main-subtitle mb-0">Atrasadas</p>
        </v-col>
        <v-col v-for="exam in lateExams" :key="'t_'+exam.id" cols="12">
          <examCard
            color="tertiary"
            :exam="exam"
            :action="() => {
              currentExam = exam;
              realizeExam = true;
              register = true;
            }"
            :detail="() => {
              detailExam = true;
              realizeExam = true;
            }"
            :toggle-attach="() => { showAttach = true; currentExam = exam; }"
          />
        </v-col>
        <v-col cols="12" v-if="nextExams.length > 0 || waitingExams.length > 0">
          <p style="font-weight: bolder" class="text-left main-subtitle mb-0">Próximas</p>
        </v-col>
        <v-col v-for="exam in waitingExams" :key="'p_'+exam.id" cols="12">
          <examCard
            color="cyan"
            :exam="exam"
            :action="() => {
              currentExam = exam;
              realizeExam = false;
              register = true;
            }"
            :detail="() => {
              detailExam = true;
              realizeExam = true;
            }"
            :toggle-attach="() => { showAttach = true; currentExam = exam; }"
          />
        </v-col>
        <v-col v-for="exam in nextExams" :key="'a_'+exam.id" cols="12">
          <examCard
            color="green"
            :exam="exam"
            btn-title="Comparecimento"
            scheduled-type
            :action="() => {
              currentExam = exam;
              realizeExam = true;
              register = true;
            }"
            :detail="() => {
              detailExam = true;
              realizeExam = true;

            }"
            :toggle-attach="() => { showAttach = true; currentExam = exam; }"
          />
        </v-col>
      </v-row>
      <v-row  v-if="activeFilter && fullfilledExams.length > 0">
        <v-col cols="12">
          <p style="font-weight: bolder" class="text-left main-subtitle mb-0">Realizadas</p>
        </v-col>
        <v-col class="col-card" v-for="exam in fullfilledExams" :key="'r_'+exam.id" cols="12">
          <examCard
            :exam="exam"
            done-type
            :action="() => {
              currentExam = exam;
              realizeExam = true;
              register = true;
            }"
            :detail="() => {
              detailExam = true;
              realizeExam = true;
            }"
            :toggle-attach="() => { showAttach = true; currentExam = exam; }"
          />
        </v-col>
      </v-row>
    </v-layout>

    <examRegister
      :success="() => registerSuccess = true"
      :close="() => { register = false; detailExam = false; showAttach= false; currentExam = null; realizeExam = false}"
      v-if="register"
      :exam="currentExam"
      :clear-exam="() => currentExam = null"
      :realize-type="realizeExam"
      :detail-only="detailExam"
      :share-attach="() => showAttach = true"
      v-on:updateExamTotalizers="updateExamTotalizers"
      :share-attach-on="showAttach"
      :refresh-exams="getExams"
      v-on:verifyIsRealized="verifyIsRealized"
    />

    <examShare
      :close="() => shareExam = false"
      :exams="shareExams"
      v-if="shareExam"
    />

    <exameAttachments
      :exam="currentExam"
      :close="() => showAttach = false"
      v-if="showAttach"
    />

    <v-container v-if="registerSuccess">
      <v-card class="success-card" flat>
        <v-container class="pa-7">
          <img src="/img/icons/exame/side-view-microscope-lab-bounded.svg" class="success-img">
          <p style="font-size: 36px" class="mt-16 pt-12 main-title">
            Exame Registrado
          </p>

          <p v-if="!isRealized" style="font-size: 24px" class="pt-4 main-text">
            Não esqueça de informar se foi ao exame
          </p>
          <generic-btn
            style="width: 85%; bottom: 10%; position: absolute"
            :btn-props="{}"
            :on-click="() => registerSuccess = false"
          >
            Ok
          </generic-btn>
        </v-container>
      </v-card>
    </v-container>

    <v-card
      style="position: fixed"
      v-if="!register && !registerSuccess"
      color="#FE7B93"
      class="plus-icon"
      elevation="10"
      @click="() => { register = true; currentExam = null; realizeExam = false; detailExam = false }"
    >
      <v-layout justify-center align-center>
        <img src="/img/icons/common/plus-icon.svg" class="mt-4">
      </v-layout>
    </v-card>

    <v-card
      style="position: fixed"
      v-if="!register && !registerSuccess"
      color="primary"
      class="share-icon"
      elevation="10"
      @click="() => { shareExam = true }"
    >
      <v-layout justify-center align-center>
        <img src="/img/icons/common/share-icon.svg" style="margin-right: 3px" class="mt-3">
      </v-layout>
    </v-card>
  </v-container>
</template>

<script>
import backArrow from '../../components/covid/back-arrow';
import Totalizer from '@/components/generic-totalizer';
import examCard from "../../components/exame/exameCard";
import examRegister from "../../components/exame/exameRegister";
import examShare from "../../components/exame/exameShare";
import exameAttachments from "../../components/exame/exameAttachments";
import { mapMutations } from 'vuex';
import moment from 'moment';
import ExamEventService from "@/services/healthcare/ExamEventService";
import { examStatus, examTypes } from '../../common/constants';


export default {
  name: 'Exam',
  components: {
    backArrow,
    Totalizer,
    examCard,
    examRegister,
    examShare,
    exameAttachments
  },
  data() {
    return {
      titleIcon: '/img/icons/exame/side-view-microscope-lab.svg',
      totals: [],
      activeFilter: false,
      lateExams: [],
      fullfilledExams: [],
      nextExams: [],
      waitingExams: [],
      moment,
      register: false,
      registerSuccess: false,
      currentExam: null,
      realizeExam: false,
      detailExam: false,
      shareExam: false,
      showAttach: false,
      isRealized: false,
    }
  },
  methods: {
    ...mapMutations(['showmsg', 'loading']),
    updateExamTotalizers(){
      this.getTotals();
    },
    verifyIsRealized(){
      this.isRealized = true
    },
    getExamTypeText(type) {
      if(type === examTypes.self)
        return 'Iniciativa própria'

      if(type === examTypes.suggestion)
        return 'Protocolo Eu Protegido'

      if(type === examTypes.refer)
        return 'Indicação médica'
    },
    getExams() {
      this.loading(true);

      this._examEventService.FindAllByUserId()
        .then(({ data }) => {
          this.lateExams = data.filter((exam) => {
            return exam.delayed && exam.status === examStatus.scheduled
          });

          this.fullfilledExams = data.filter((exam) => {
            return exam.status === examStatus.fullfilled
          });

          this.nextExams = data.filter((exam) => {
            return exam.status === examStatus.scheduled
          });

          this.waitingExams = data.filter((exam) => {
            return exam.status === examStatus.waiting
          });

          this.shareExams = data.filter((exam) => {
            return exam.attachments.length > 0
          })
        })
        .catch(() =>
          this.showmsg({
            text: "Erro ao carregar os dados dos exames!",
            type: "error",
          })
        )
        .finally(() => this.loading(false));
    },
    getTotals() {
      this.loading(true);

      this._examEventService.countExamTotals()
        .then(({ data }) => {
          this.totals = [{
            label: 'Sugestões',
            value: data.totalSuggestionLastYear
          }, {
            label: 'Próximas',
            value: data.totalSuggestionAndScheduledLastYear
          }, {
            label: 'Histórico',
            value: data.totalRealizeds
          },]
        })
        .catch(() =>
          this.showmsg({
            text: "Erro ao carregar totalizadores!",
            type: "error",
          })
        )
        .finally(() => this.loading(false));
    },
  },
  beforeCreate() {
    this._examEventService = new ExamEventService();
  },
  mounted() {
    this.getTotals();
    this.getExams();
  }
}
</script>

<style>
/*.v-icon.v-icon  {*/
/*  color: var(--v-tertiary) !important;*/
/*  font-size: 15px !important;*/
/*  padding-left: 20px !important;*/
/*}*/

.list-card {
  border-radius: 20px !important;
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.1) !important;
}

.option-list-card {
  width: 100% !important;
  height: 65px;
}

.plus-icon {
  border-radius: 50% !important;
  height: 60px;
  width: 60px;
  position: fixed !important;
  right: 30px;
  bottom: 30px;
}

.share-icon {
  border-radius: 50% !important;
  height: 45px;
  width: 45px;
  position: fixed !important;
  right: 38px;
  bottom: 110px;
}

.success-card {
  position: absolute;
  bottom: 0;
  left: 7.5%;
  height: 80vh;
  width: 85vw;
  border-top-right-radius: 20px !important;
  border-top-left-radius: 20px !important;
  z-index: 10;
}

@media (max-width: 379px) {
  .success-img {
    z-index: 20;
    position: absolute;
    left: 24%;
    top: -100px;
  }
}

@media (min-width: 380px) {
  .success-img {
    z-index: 20;
    position: absolute;
    left: 27.5%;
    top: -100px;
  }
}

@media (min-width: 320px) {
  .col-card{
    margin-right: -100px !important;
  }
}

@media (min-width: 420px) {
  .col-card{
    margin-right: -150px !important;
  }
}

@media (max-width: 389px) {
  .filter-text {
    line-height: 100% !important;
    font-size: 13px !important;
    font-weight: bolder !important;
  }

  .history-margin-adjust {
    margin-top: 4px !important;
  }
}

@media (min-width: 390px) {
  .filter-text {
    line-height: 100% !important;
    font-size: 16px !important;
    font-weight: bolder !important;
  }

  .history-margin-adjust {
    margin-top: 2px !important;
  }
}

.background-glass-effect{
  background: rgba(255, 255, 255, .5);
  mix-blend-mode: normal;
  padding: 10px;
}
</style>
