<template>
  <v-container class="share-screen">
    <v-row>
      <v-col class="pb-0 py-5" cols="10">
      </v-col>
      <v-col class="pb-0" cols="2">
        <img @click="() => { close(); }" src="/img/icons/common/close-icon.svg" style="height: 35px;" />
      </v-col>
    </v-row>
    <div style="margin-top: 50%" v-if="link">
      <p class="main-title text-center pl-6 pr-8">
        Para efetuar o compartilhamento faça o acesso pelo botão
      </p>

      <generic-btn
        style="width: 90%; margin-top: 7%"
        class="ml-5"
        :btn-props="{ }"
        :on-click="() => nativeShare()"
      >
        Compartilhar anexos
      </generic-btn>
    </div>
    <div v-if="!link">
      <p class="main-title pl-2 pr-8">
        Anexos
      </p>
      <v-list v-if="exam.attachments">
        <v-list-item v-for="attach in exam.attachments" :key="attach.fileName" two-line>
          <v-list-item-content>
            <v-list-item-title style="font-size: 20px">{{ attach.attachmentType.name }}</v-list-item-title>
            <v-list-item-subtitle style="font-size: 20px">{{ attach.fileName }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>

    <v-card v-if="!link" style="bottom: -20px" elevation="16" class="step-btn">
      <generic-btn
        style="width: 90%; margin-top: 7%"
        class="ml-5"
        :btn-props="{}"
        :on-click="() => shareExam()"
      >
        Compartilhar
      </generic-btn>
    </v-card>

    <shareWarn :acc-term="accTerms" :btn-action="toggleDialog" />
  </v-container>
</template>

<script>
import moment from 'moment';
import shareWarn from './shareWarn';
import ExamEventService from "@/services/healthcare/ExamEventService";
import { mapMutations } from 'vuex';

export default {
  name: 'exameShare',
  components: {
    shareWarn
  },
  props: {
    close: {
      type: Function,
      required: true
    },
    exam: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      moment,
      link: null,
      accTerms: true
    }
  },
  methods: {
    ...mapMutations(['showmsg', 'loading']),
    toggleDialog() {
      this.accTerms = !this.accTerms;
    },
    async nativeShare() {
      if (navigator.share) {
        try {
          let share = {
            url: this.link.link,
            title: 'Eu Protegido',
            text: this.link.text,
          }
          await navigator.share(share);
        } catch (error) {
          this.showmsg({
            text: "Não é possivel compartilhar por este dipositivo.",
            type: "red"
          });
        }
      }else {
        this.showmsg({
          text: "Não é possivel compartilhar por este dipositivo.",
          type: "red"
        });
      }
    },
    shareExam() {
      this.loading(true);

      this._examEventService.SharingExamAttachment([this.exam.id])
        .then(({ data }) => {
          this.link = data
        })
        .catch(() =>
          this.showmsg({
            text: "Erro ao compartilhar!",
            type: "error",
          })
        )
        .finally(() => this.loading(false));
    }
  },
  beforeCreate() {
    this._examEventService = new ExamEventService();
  },
  beforeMount() {
    window.scrollTo(0, 0)
  }
}
</script>

<style scoped>
.share-screen {
  height: 100%;
  background: white;
  width: 100vw;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 5;
}

.step-btn {
  border-top-left-radius: 40px !important;
  border-top-right-radius: 40px !important;
  width: 100vw;
  height: 129px;
  position: fixed;
  top: 85%;
  left: 0;
  z-index: 10;
}
</style>