<template>
  <v-card @click="() => { action(); detail(); }" min-height="200" max-height="240" color="white" class="list-card scale-up-center">
    <v-container class="pa-6 pb-0">
      <p style="font-weight: bolder" class="mb-0 generic-text text-truncate">
        {{ specialityName }}
      </p>
      <v-chip class="mb-3" x-small color="#E9EBFD" style="color: var(--v-primary); font-weight: 600">
        {{ getExamTypeText(exam.examType && exam.examType.code) }}
      </v-chip>
      <p v-if="!doneType" style="font-size: 12px" class="main-strong-text">
        {{ getDateText(exam) }}
      </p>

      <p v-if="doneType" style="font-size: 12px" class="main-strong-text">
        Realizado em {{ moment(exam.realizationDate).format('DD/MM/YYYY') }}
      </p>

      <p style="font-size: 14px;" class="main-subtitle text-truncate mb-0">
        {{ labName }}
      </p>
      <p style="font-size: 14px;" class="main-strong-text text-truncate">
        {{ exam.place || 'Local não informado' }}
      </p>
      <v-row v-if="!doneType">
        <v-col class="pr-1" :cols="!scheduledType ?  7 : 5">
          <v-row :class="scheduledType && 'mt-2'">
            <v-col class="pr-2" :cols="scheduledType ?  3 : 2">
              <pulseIcon :color="color" />
            </v-col>
            <v-col v-if="!scheduledType" class="pl-0" cols="10">
              <p style="font-size: 12px" class="main-strong-text mb-10">
                Aguardando <br/> agendamento sugerido
              </p>
            </v-col>
            <v-col v-if="scheduledType" class="pl-0" cols="9">
              <p  style="font-size: 12px" class="main-strong-text mb-10">
                Agendado
              </p>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="pt-6 pl-0" :cols="scheduledType ?  7 : 5">
          <v-btn class="card-btn" @click="action" :color="color">
            {{ btnTitle }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-row class="pl-6 pr-6" justify="start" v-if="doneType">
      <v-col cols="8">
        <v-row v-if="exam.realizationWithDelay" class="mt-3">
          <v-col class="pt-0" cols="2">
            <pulseIcon color="green"/>
          </v-col>
          <v-col class="pt-0 pb-6" cols="10">
            <p style="font-size: 12px" class="main-strong-text mb-0">
              Realizada fora do prazo
            </p>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col cols="3">
            <img
              src="/img/icons/home/medal-badge.svg"
              style="width: 40px; height: 40px"
            >
          </v-col>
          <v-col class="mt-2" cols="9">
            <p style="font-size: 12px" class="main-strong-text mb-0">
              Realizada no prazo
            </p>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4">
        <v-row v-if="exam.attachments && exam.attachments.length > 0" @click="toggleAttach">
          <v-col class="pa-0 pt-5" cols="2">
            <img
              src="/img/appointments/clip-icon.svg"
              style="width: 20px; height: 20px;"
            />
          </v-col>
          <v-col class="pa-0 pt-4 pl-2" cols="10">
            <p
              class="generic-text"
              style="font-size: 14px"
            >
              Anexos
            </p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import moment from 'moment';
import pulseIcon from '@/components/pulse-icon';
import {examTypes, examStatus} from '../../common/constants';

export default {
  name: 'ExamCard',
  components: { pulseIcon },
  props: {
    exam: {
      type: Object,
      required: true
    },
    color: {
      type: String,
      default: 'blue'
    },
    doneType: {
      default: false,
      type: Boolean
    },
    scheduledType: {
      default: false,
      type: Boolean
    },
    action: {
      type: Function,
      required: false
    },
    detail: {
      type: Function,
      required: false
    },
    btnTitle: {
      type: String,
      default: 'Registrar'
    },
    toggleAttach: {
      type: Function,
      required: false
    }
  },
  data() {
    return {
      moment
    }
  },
  computed: {
    specialityName() {
      if(this.exam.examSpeciality && this.exam.examSpeciality.description){
        if(this.exam.examSpeciality.description === 'Outros'){
          return `${this.exam.examSpeciality.description} - ${this.exam.comment}`
        }else{
          return this.exam.examSpeciality.description
        }
      }else{
        return 'Exame não informado'
      }
    },
    labName() {
      if(this.exam && this.exam.laboratoryName) {
        return this.exam.laboratoryName !== ' ' ?
            this.exam.laboratoryName : 'Laboratório não informado'
      }else {
        return 'Laboratório não informado';
      }
    }
  },
  methods: {
    getExamTypeText(type) {
      if(type === examTypes.self)
        return 'Iniciativa própria'

      if(type === examTypes.suggestion)
        return 'Protocolo Eu Protegido'

      if(type === examTypes.refer)
        return 'Indicação médica'
    },
    getDateText(exam) {
      if(exam.status === examStatus.waiting)
        return `Realize até ${ moment(exam.realizationUntilDate).format('DD/MM/YYYY') }`

      if(exam.status === examStatus.scheduled)
        return `Data agendada ${ moment(exam.scheduledDate).format('DD/MM/YYYY') }`
    },
  }
}
</script>

<style scoped>
.card-btn {
  border-radius: 20px !important;
  color: white !important;
  text-transform: initial !important;
  font-weight: bolder !important;
}

/*.v-icon.v-icon  {*/
/*  color: var(--v-tertiary) !important;*/
/*  font-size: 15px !important;*/
/*  padding-left: 20px !important;*/
/*}*/

.list-card {
  border-radius: 20px !important;
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.1) !important;
  max-width: 90vw !important;
  width: auto;
}

.scale-up-center {
  -webkit-animation: scale-up-center 0.3s ease-in both;
  animation: scale-up-center 0.3s ease-in both;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.option-list-card {
  width: 100% !important;
  height: 65px;
}
</style>
